import React, { useState } from "react";
import PropTypes from "prop-types";
import StatusDone from "images/icons/status-done.svg";
import StatusSelected from "images/icons/status-selected.svg";
import StatusNext from "images/icons/status-next.svg";
import StatusNotYet from "images/icons/status-notyet.png";
import { useAuth } from "components/AuthProvider";
import { useForm } from "react-hook-form";

const getStatusIdByLabel = (label, list) => {
  const status = list.find((status) => status.label === label);
  return status ? status.id : null;
};

const hrDone = "mt-[-20px] border-1 border-solid border-green";
const hrNext = "mt-[-20px] border-1 border-dashed border-green";
const hrNotyet = "mt-[-20px] border-1 border-solid border-lightgray";
const hrTransparent = "mt-[-20px] border-1 border-solid border-white";

export default function StatusTimeline({
  id,
  statusList,
  status,
  updateStatus,
  error,
  setError,
}) {
  const { register, handleSubmit } = useForm();
  const [current, setCurrent] = useState();
  const [isSubmitting, setSubmitting] = useState(false);
  const { isManager, role } = useAuth();
  const canChangeStatus = isManager && role !== "Consultation";
  const handleSelect = (e) => {
    setCurrent(e.target.value);
  };

  const onSubmit = async () => {
    if (isSubmitting || current === status || !current) {
      return;
    }
    setSubmitting(true);
    document.activeElement?.blur();
    try {
      setError("");
      await updateStatus(id, current);
      setCurrent(undefined);
    } catch (error) {
      setError(
        `${error?.message ? error?.message : ""} ${error?.data?.join(", ") ? error?.data?.join(", ") : ""}`,
      );
      console.warn("Error updating file:", error);
    }
    setSubmitting(false);
  };

  const currentID = getStatusIdByLabel(status, statusList);

  const statusItems = statusList.map((item) => (
    <div className="grow" key={item.id}>
      <label className={canChangeStatus ? "cursor-pointer" : ""}>
        <img
          src={
            current === item.label
              ? StatusSelected
              : currentID === item.id
                ? item.id === statusList.length
                  ? StatusDone
                  : StatusNext
                : currentID > item.id
                  ? StatusDone
                  : currentID === item.id
                    ? StatusNext
                    : StatusNotYet
          }
          alt="status"
        />
        <div className="mt-2 mb-5">
          <hr
            className={
              currentID - 1 === item.id
                ? item.id === statusList.length - 1
                  ? hrDone
                  : hrNext
                : currentID > item.id
                  ? hrDone
                  : item.id === statusList.length
                    ? hrTransparent
                    : hrNotyet
            }
          />
        </div>
        <input
          {...register("statut")}
          type="radio"
          value={item.label}
          name="statut"
          className="hidden"
          onChange={canChangeStatus ? handleSelect : () => null}
        />
        <p className={item.wider ? `text-xs w-[80px]` : `text-xs w-[60px]`}>
          <span
            className={
              current === item.label
                ? "text-main-color font-semibold"
                : currentID >= item.id
                  ? "text-green font-semibold"
                  : currentID + 1 === item.id
                    ? "text-neutral-400"
                    : "text-neutral-400"
            }
          >
            {item.label}
          </span>
        </p>
      </label>
    </div>
  ));

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div>
        <div className="flex flex-row items-start w-full">
          {statusItems}
          {canChangeStatus && (
            <button
              className="flex text-sm text-main-color font-bold underline"
              type="submit"
            >
              Valider statut
            </button>
          )}
        </div>
        <div className="max-w-[1550px]">
          <p className="text-right text-red-500 text-xs mt-2">{error}</p>
        </div>
      </div>
    </form>
  );
}
StatusTimeline.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  statusList: PropTypes.array,
  status: PropTypes.string,
  updateStatus: PropTypes.func,
  error: PropTypes.any,
  setError: PropTypes.func,
};
